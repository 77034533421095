import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom'

import classes from './navigation.module.css'
const Navigation = (props) =>{
    let cls= [classes.drawer]
    if (!props.isOpen) {
      
      cls.push(classes.close)
    }
    return(
        <nav className={cls.join(' ')}>
        <ul>
          <li>
            <Link to="/" ><HomeIcon/>Главная</Link>
          </li>
          <li>
            {' '}
            <Link to="/favorit" ><BookmarkBorderIcon/>Избранное</Link>{' '}
          </li>
          <li>
            {' '}
            <Link to="/contact" ><ConnectWithoutContactIcon/>Контакты</Link>{' '}
          </li>
        </ul>
      </nav>
    )

}
export default Navigation