import { connect } from 'react-redux'
// import Main from './containers/main/main'
import { getSelectVideoStoreg } from './redux/action'
import useRouter from './router/useRouter'
function App(props) {
  const routers = useRouter()
  return (<>{routers}</>)
}
const mapStateToProps = (state) => ({
  storeg: state.app.storegLoadin,
})
const mapDispathToProps = {
  getSelectVideoStoreg,
}
export default connect(mapStateToProps, mapDispathToProps)(App)
