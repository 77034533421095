import {
  DROP_FILM,
  GET_DATA_API,
  GET_FILM_DATA,
  HIDDEN_LOADER,
  HIDEN_ALERT,
  LOAD_TOP,
  SELECT_VIDEO,
  SET_STOREG_DATA,
  SHOW_ALERT,
  SHOW_LOADER,
  STOREG_NULL,
} from './type'
const storegName = 'video_data'
export function showAlert() {
  return (dispatch) => {
    dispatch({
      type: SHOW_ALERT,
    })

    setTimeout(() => {
      dispatch(hidenAlert())
    }, 3000)
  }
}
export function hidenAlert() {
  return {
    type: HIDEN_ALERT,
  }
}
export function showLoader() {
  return {
    type: SHOW_LOADER,
  }
}
export function hidenLoader() {
  return {
    type: HIDDEN_LOADER,
  }
}
export function getDataFilm(text, pageId = 1) {
  return async (dispatch) => {
    dispatch(showLoader())
    try {
      const response = await fetch('/api/search-by-key/'+text+'/page/'+pageId, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
      let data = await response.json()
      if (response.status === 200) {
        if (data.searchFilmsCountResult === 0) {
          dispatch(showAlert())
        } else {
          data.error = false
          data.pageSelect = pageId
          dispatch({ type: GET_DATA_API, payload: data })
        }
      } else {
        dispatch(showAlert())
      }
    } catch (e) {
      dispatch(showAlert())
    }
  }
}
export function getSelectVideoStoreg() {
 
  return (dispatch) => {
    try {
      const data = JSON.parse(localStorage.getItem(storegName))
      if (data) {
        dispatch({ type: SET_STOREG_DATA, list: data.list, id: data.idVideo })
      } else {
        dispatch({ type: STOREG_NULL })
      }
    } catch {
      dispatch({ type: STOREG_NULL })
    }
   
  }
}
export function selectVideo(id, list) {
  return (dispatch) => {
    localStorage.setItem(
      storegName,
      JSON.stringify({
        idVideo: id,
        list: list,
      }),
    )
    dispatch({ type: SELECT_VIDEO, payload: id })
  }
}
//https://yoioio.pw/vpars/parsing.php

export function dropFiml(){

  return async (dispatch)=>{

    dispatch({ type: DROP_FILM, payload: [] })

  }
}
export function getTopFilm(pageId = 1){
  return async (dispatch) =>{
    // dispatch(showLoader())
    try{
      const response = await fetch('/api/top/'+pageId, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET'
      })
      let data = await response.json()
      if (response.status === 200) {
        data.pageSelect = pageId
        data.error = false
        dispatch({ type: LOAD_TOP, payload: data })
      }
    }
    catch(error){
      console.log(error)
      dispatch({ type: LOAD_TOP, payload: {error:true} })
    }
    
  } 


}
export function getFilmInfo(idFilm){
  return async (dispatch) =>{
    try{
      const response = await fetch('/api/film/'+idFilm, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
      let data = await response.json()
      if(response.status === 200)
      {
        data.error = false
        dispatch({ type: GET_FILM_DATA, payload: data })
      }
    }
    catch{
      dispatch({ type: GET_FILM_DATA, payload: [] })
    }
  }
}
export function getFilterFilm(page, type, gener){
  return async (dispatch) =>{
    try{
      const response = await fetch('/api/filter/page/'+page+'/gener/'+gener+'/type/'+type, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
      let data = await response.json()
      if(response.status === 200)
      {
        data.pageSelect = page
        data.type = type
        data.gener = gener
        data.error = false
        dispatch({ type: GET_DATA_API, payload: data })
      }
    }
    catch{
      dispatch({ type: GET_DATA_API, payload: [] })
    }
  }
}
