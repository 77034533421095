import classes from './header.module.css'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import logo from '../../img/logomak_logo.png'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDataFilm, dropFiml } from '../../redux/action'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useHistory } from 'react-router-dom'
import Filter from '../filter/filter'
import { useState } from 'react'
import SingIn from '../../page/singIn/singIn'
import Registration from '../../page/registration/registration'
const HeaderPanel = (props) => {
  const history = useHistory()
  const [isOpenFilte, setIsOpenFilter] = useState(false)
  const [isSingIn, setSingIn] = useState(false)
  const [isRegistOpen, setIsRegistOpen] = useState(false)

  function isOpenHandler() {
    props.setIsOpen(!props.isOpen)
  }
  function isRegistOpenHandler(e)
  {
    e.preventDefault();
    setSingIn(!isSingIn)
    setIsRegistOpen(!isRegistOpen)
  }

  function searchFilm(e) {
    e.preventDefault()
    if (e.target[0].value !== '') props.getDataFilm(e.target[0].value)
    history.push('/')
    console.log(e.target[0].value)
  }

  function dropFilmHandler() {
    if (props.dataFilm.keyword || props.dataFilm.gener || props.dataFilm.type) {
      props.dropFiml()
    }
  }
  function isSingInHandler(){
    setSingIn(!isSingIn)
    setIsRegistOpen(false)

  }
  function openFillter(e) {
    setIsOpenFilter(!isOpenFilte)
  }

  return (
    <div>
       {isSingIn &&  <SingIn setIsOpen ={isSingInHandler} setIsRegits = {isRegistOpenHandler} />}
       {isRegistOpen &&  <Registration   setIsOpen ={isSingInHandler}/>}
      <header className={classes.header}>
        <Button
          onClick={isOpenHandler}
          style={{ float: 'left', width: '20px', zIndex: '100' }}
        >
          {!props.isOpen ? <MenuIcon /> : <CloseIcon />}
        </Button>
        {isOpenFilte && <Filter isOpenHandler={openFillter} />}

        <Link to="/" onClick={dropFilmHandler} className={classes.toMain}>
          <img alt="" src={logo}></img>
        </Link>

        <form className={classes.headSearch} onSubmit={searchFilm}>
          <input className={classes.searchInput} placeholder="Поиск"></input>
          <button type="submit" className={classes.button}>
            <SearchIcon></SearchIcon>
          </button>
          <button className={classes.button} onClick={openFillter}>
            <FilterAltIcon />
          </button>
        </form>
        <Button onClick={isSingInHandler} style={{ float: 'right', width: '20px', marginLeft: 'auto' }}>
          <LogoutIcon></LogoutIcon>
        </Button>
      </header>
    </div>
  )
}
const mapDispathToProps = {
  getDataFilm,
  dropFiml,
}
const mapStateToProps = (state) => ({
  dataFilm: state.app.dataFilm,
})
export default connect(mapStateToProps, mapDispathToProps)(HeaderPanel)
