export const genresAll = [
  {
    id: 26,
    genre: 'ток-шоу',
  },
  {
    id: 1750,
    genre: 'аниме',
  },
  {
    id: 22,
    genre: 'биография',
  },
  {
    id: 3,
    genre: 'боевик',
  },
  {
    id: 13,
    genre: 'вестерн',
  },
  {
    id: 19,
    genre: 'военный',
  },
  {
    id: 17,
    genre: 'детектив',
  },
  {
    id: 456,
    genre: 'детский',
  },
  {
    id: 12,
    genre: 'документальный',
  },
  {
    id: 8,
    genre: 'драма',
  },
  {
    id: 27,
    genre: 'игра',
  },
  {
    id: 23,
    genre: 'история',
  },
  {
    id: 6,
    genre: 'комедия',
  },
  {
    id: 1747,
    genre: 'концерт',
  },
  {
    id: 15,
    genre: 'короткометражка',
  },
  {
    id: 16,
    genre: 'криминал',
  },
  {
    id: 7,
    genre: 'мелодрама',
  },
  {
    id: 21,
    genre: 'музыка',
  },
  {
    id: 14,
    genre: 'мультфильм',
  },
  {
    id: 9,
    genre: 'мюзикл',
  },
  {
    id: 28,
    genre: 'новости',
  },
  {
    id: 10,
    genre: 'приключения',
  },
  {
    id: 25,
    genre: 'реальное ТВ',
  },
  {
    id: 11,
    genre: 'семейный',
  },
  {
    id: 24,
    genre: 'спорт',
  },
  {
    id: 4,
    genre: 'триллер',
  },
  {
    id: 1,
    genre: 'ужасы',
  },
  {
    id: 2,
    genre: 'фантастика',
  },
  {
    id: 18,
    genre: 'фильм-нуар',
  },
  {
    id: 5,
    genre: 'фэнтези',
  },
]
export const genresSerials = [
   
    {
      id: 1750,
      genre: 'аниме',
    },
    {
      id: 3,
      genre: 'боевик',
    },
    {
      id: 13,
      genre: 'вестерн',
    },
    {
      id: 19,
      genre: 'военный',
    },
    {
      id: 17,
      genre: 'детектив',
    },
    {
      id: 456,
      genre: 'детский',
    },
    {
      id: 12,
      genre: 'документальный',
    },
    {
      id: 8,
      genre: 'драма',
    },
    {
      id: 27,
      genre: 'игра',
    },
    {
      id: 23,
      genre: 'история',
    },
    {
      id: 6,
      genre: 'комедия',
    },
    {
      id: 16,
      genre: 'криминал',
    },
    {
      id: 7,
      genre: 'мелодрама',
    },
    {
      id: 14,
      genre: 'мультфильм',
    },
    {
      id: 10,
      genre: 'приключения',
    },
    {
      id: 11,
      genre: 'семейный',
    },
    {
      id: 24,
      genre: 'спорт',
    },
    {
      id: 4,
      genre: 'триллер',
    },
    {
      id: 1,
      genre: 'ужасы',
    },
    {
      id: 2,
      genre: 'фантастика',
    },
    {
      id: 5,
      genre: 'фэнтези',
    },
  ]
  