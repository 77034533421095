import classes from './mainPage.module.css'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Navigation from '../../component/navigation/navigation'
import HeaderPanel from '../../component/header/header'
import { getTopFilm, getDataFilm,getFilterFilm } from '../../redux/action'
import ItemFilm from '../../component/itemFilm/itemFilm'
import Loader from '../../containers/loader/loader'
import { Pagination } from '@mui/material'
const MainPage = (asyncProps) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (asyncProps.dataFilm.length === 0) {
      asyncProps.getTopFilm()
    }
  })
  function nextPage(e, p) {
    if (asyncProps.dataFilm.keyword) {
      asyncProps.getDataFilm(asyncProps.dataFilm.keyword, p)
    } else if(asyncProps.dataFilm.type && asyncProps.dataFilm.gener) {
      asyncProps.getFilterFilm(p,asyncProps.dataFilm.type,asyncProps.dataFilm.gener)
    }
    else{
      asyncProps.getTopFilm(p)
    }

    window.scrollTo(0, 0)
  }
  return (
    <div style={{ display: 'block' }}>
      <HeaderPanel setIsOpen={setIsOpen} isOpen={isOpen}></HeaderPanel>
      <Navigation isOpen={isOpen}></Navigation>
      <section className={classes.container}>
        
        {asyncProps.dataFilm.error === true ? (<div>Нет ответа от сервера</div>) : asyncProps.dataFilm.length === 0 ? (
          <Loader />
        ) : (
          asyncProps.dataFilm.films.map((film) => {
            return (
              film.nameRu &&
              <ItemFilm
                key={film.filmId}
                id={film.filmId}
                name={film.nameRu}
                year={film.year}
                img={film.posterUrlPreview}
                rating={film.rating}
                genres={film.genres}
              />
            )
          })
        )}
      </section>
      <div style={{ display: 'flex' }}>
        {asyncProps.dataFilm.pagesCount && (
          <Pagination
            style={{ marginLeft: 'auto', marginRight: 'auto', padding: '20px' }}
            onChange={nextPage}
            count={asyncProps.dataFilm.pagesCount}
            size="large"
            page={Number(asyncProps.dataFilm.pageSelect)=== 0 ?1:Number(asyncProps.dataFilm.pageSelect) }
          ></Pagination>
        )}
      </div>
    </div>
  )
}
// const mapStateToProps = (state) => ({
//   isAuth: state.authReducer.isAuth,
//   router: state.router
// })

const mapDispathToProps = {
  getTopFilm,
  getDataFilm,
  getFilterFilm
}
const mapStateToProps = (state) => ({
  dataFilm: state.app.dataFilm,
})
export default connect(mapStateToProps, mapDispathToProps)(MainPage)
