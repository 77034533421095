import { useParams } from 'react-router'
import HeaderPanel from '../../component/header/header'
import Navigation from '../../component/navigation/navigation'
import {useState, useEffect, useRef} from 'react'
import classes from './film_new.module.css'
import { Rating } from '@mui/material'
import {getFilmInfo} from '../../redux/action'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { connect } from 'react-redux'
import Loader from '../../containers/loader/loader'
const FilmNew = (asyncProps) => {
    const { id } = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const frameFilmVideoCDN = `<iframe style="width: 100%; height:500px;bottom: 0;padding-bottom: 20px; margin-left: auto;margin-right: auto;" src="https://886534688564.svetacdn.in/qnVDGzjLy4iP?kp_id=${id}"  frameborder="0" allowfullscreen></iframe>`
    const frameFilmBozon = `<iframe src="https://voidboost.tv/embed/${id}" frameborder="0" scrolling="no" allowfullscreen="" referrerpolicy="origin" style="width: 100%; height:500px;bottom: 0;padding-bottom: 20px; margin-left: auto;margin-right: auto;"></iframe>`
    const kinobox = `kinobox`
    const kinoplaytop ='kinoplaytop'
    const [url, setUrl] = useState(frameFilmVideoCDN)
    const contentRef = useRef(null);
    const handelrPlayer = (e) => {
        switch (e.target.value){
            case 'cdn':

                setUrl(frameFilmVideoCDN)
                break;
            case 'bozon':

                setUrl(frameFilmBozon)
                break;
            case 'kinobox':

                setUrl(kinobox)

                break;
            case 'kinoplaytop':

                setUrl(kinoplaytop)

        }
      if(e.target.value === "cdn"){
        setUrl(frameFilmVideoCDN)
      } 
      else if(e.target.value === "bozon"){
        setUrl(frameFilmBozon)
      } 
    }
    const handlerFrame = (url)=>{
        switch (url) {
            case 'kinobox' :

                return <div ref={contentRef} className={`kinobox_player ${classes.videoPanel}`}></div>
            case 'kinoplaytop':
                if (contentRef.current) {
                    contentRef.current.innerHTML = '';
                }
                return <div className={classes.videoPanel} >
                    <div id="kinoplayertop" data-kinopoisk={id}></div>
                </div>
            default:

                return <div className={classes.videoPanel} dangerouslySetInnerHTML={{__html: url}}></div>
        }
    }
    useEffect(() => {
        document.title = `${asyncProps.filmInfo.nameRu } | yoioio-Кинотеатр`
        if (asyncProps.filmInfo.length === 0 || Number(asyncProps.filmInfo.kinopoiskId) !== Number(id)) {
          asyncProps.getFilmInfo(id)
        }
      })
      useEffect(()=>{

              if(url === 'kinobox')
                  global.kbox('.kinobox_player', { search: {kinopoisk: id}})

              if(url === 'kinoplaytop')
                  global.kinoplayer_run();

      }, [url])
    return (  asyncProps.filmInfo.length !== 0 && Number(asyncProps.filmInfo.kinopoiskId) === Number(id)  ?
    <>
        <HeaderPanel setIsOpen={setIsOpen} isOpen={isOpen}></HeaderPanel>
        <Navigation isOpen={isOpen}></Navigation>
        <div className={classes.ctBackground+" container"}>
            <div className={classes.flexcolumn}>
                <div className={classes.flexrow + ' ' + classes.flexbeetween}>
                    <h2 className={classes.title}>{asyncProps.filmInfo.nameRu}</h2>
                    <button><BookmarkBorderIcon></BookmarkBorderIcon></button>
                </div>
                <div className={classes.flexPanelInfo + ' ' + classes.marginTop}>
                    <div>
                        <img
                            className={classes.poster}
                            alt=""
                            src={asyncProps.filmInfo.posterUrlPreview}
                        ></img>
                    </div>
                    <div>
                        <p style={{display: 'inline-flex'}}>
                            Рейтинг: <Rating name="read-only" value={Number(asyncProps.filmInfo.ratingKinopoisk) / 2}
                                             readOnly/>
                        </p>
                        <p>Год: {asyncProps.filmInfo.year}</p>
                        <p>Страна: {asyncProps.filmInfo.countries.map((el) => el.country + " ")}</p>
                        <p>Жанр: {asyncProps.filmInfo.genres.map((el) => el.genre + " ")}</p>
                        <p className={classes.description}>
                            {asyncProps.filmInfo.description}
                        </p>

                    </div>
                </div>
                <div>
                    <button className={classes.pButton} value={'cdn'} onClick={handelrPlayer}>Плеер 1</button>
                    {/*<button className={classes.pButton} value={'bozon'} onClick={handelrPlayer}>Плеер 2</button>*/}
                    <button className={classes.pButton} value={'kinobox'} onClick={handelrPlayer}>Плеер 2</button>
                    {/*<button className={classes.pButton} value={'kinoplaytop'} onClick={handelrPlayer}>Плеер 3</button>*/}
                </div>

                    {handlerFrame(url)}



            </div>
        </div>
    </> : <Loader/>)
}
const mapDispathToProps = {
    getFilmInfo,
}
const mapStateToProps = (state) => ({
    filmInfo: state.app.filmInfo
  })
export default connect(mapStateToProps,mapDispathToProps)(FilmNew)
