import classes from './filter.module.css'
import { genresAll, genresSerials } from '../../object/genres'
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { getFilterFilm } from '../../redux/action'
import { useHistory } from 'react-router-dom'
const Filter = (props) =>{
    const history = useHistory()
    function getFilm (e)
    {
        e.preventDefault()
        props.isOpenHandler()      
        props.getFilterFilm(1,e.target.name,e.target.id)
        history.push('/')
        // console.log(e.target.name +"   "+e.target.id)
        // console.log(props.router)
    }

    return(
        <div className={classes.filterContainer}>
             
            <div className={classes.list}>
                <button className={classes.close} onClick={props.isOpenHandler} ><CloseIcon/></button>
                <h2>Все</h2>
                <div className={classes.gener}>
                    {genresAll.map((el) =><button onClick={getFilm} name='ALL' key={el.id} id={el.id}>{el.genre}</button>)}
                </div>
                <h2>Сериалы</h2>
                <div className={classes.gener}>
                    {genresSerials.map((el) =><button onClick={getFilm} name ='TV_SHOW' key={el.id} id={el.id}>{el.genre}</button>)}
                </div>
            </div>
        </div>
    )
}
const mapDispathToProps = {
    getFilterFilm
  
  }
  const mapStateToProps = (state) => ({
    router: state.router,
  })
export default connect(mapStateToProps,mapDispathToProps)(Filter)