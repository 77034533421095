import classes from './singIn.module.css'
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
const SingIn = (props)=>
{
    const [login, setLogin] = useState('')
    const [pass, setPass] = useState('')
    const [errorInf, setErrorInf] = useState('')
    

    const handelrForm = (e) =>{
    e.preventDefault()
    if(login === "") {setErrorInf("Введите логин")}
    else if(pass === "") {setErrorInf("Введите пароль")}
    else{

    }
    
    console.log(login +'  '+pass)
    }
    return(
        <div className={classes.panel}>
            <button className={classes.close} onClick={props.setIsOpen}><CloseIcon/></button>
            <form className={classes.form} onSubmit={handelrForm}>
                
                <input name="login" placeholder="Логин" onChange={(e) => {setLogin(e.target.value); errorInf && setErrorInf("")}}></input>
                <input type="password" name="password" placeholder="Пароль" onChange={(e) => {setPass(e.target.value); errorInf && setErrorInf("")}}></input>
                <p style={{color:'white', marginRight:'auto', marginLeft:'auto'}}>{errorInf}</p>
                <div>
                    <button type='submit'>Войти</button>
                    <button onClick={props.setIsRegits}>Регистрация</button>
                </div>

               
            </form>

        </div>
    )

}
export default SingIn