export const SHOW_ALERT ='SHOW_ALERT'
export const HIDEN_ALERT = 'HIDEN_ALERT'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDDEN_LOADER ='HIDDEN_LOADER'
export const GET_DATA_API = 'GET_DATA_API '
export const SELECT_VIDEO = 'SELECT_VIDEO'
export const SET_STOREG_DATA = 'SET_STOREG_DATA'
export const STOREG_NULL = 'STOREG_NULL'
export const LOAD_TOP = 'LOAD_TOP'
export const GET_FILM_DATA = 'GET_FILM_DATA'
export const DROP_FILM = 'DROP_FILM'