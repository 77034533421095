import classes from './itemFilm.module.css'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Link } from 'react-router-dom'
const ItemFilm = (props) =>{


    return (<div className={classes.itemFilm}>
        <Link to={"film/"+props.id} >
          <img
            alt={props.name}
            src={"https://st.kp.yandex.net/images/film_iphone/iphone360_"+props.id+".jpg"}
          ></img>
        </Link>
        <span className={classes.nameFilm}>
          <Link to={"film/"+props.id}>{props.name}</Link>
        </span>
        <span className={classes.infoFilm}>
            {props.year +","+ props.genres.map((el)=> el.genre)}
         {/* 2021,Комедия боевик ужасы трейлер */}
        </span>
        <span  className={classes.ratingFilm}>
          Рейтинг: {props.rating}
        </span>
        <span className={classes.watch}>
          <Link to={"film/"+props.id}>
            <PlayCircleOutlineIcon />
          </Link>
        </span>
      </div>)


}

export default ItemFilm