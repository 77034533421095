import { Redirect, Route, Switch }  from "react-router"
import Contact from "../page/contact/contact"
import Favorites from "../page/favorites/favorites"
// import Film from "../page/film/film"
import FilmNew from "../page/film/film_new"
import MainPage from "../page/mainPage/mainPage"
const useRouter = ()=>
{
    return(<Switch>
        <Route exact path="/" component={MainPage}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/favorit" component={Favorites}></Route>
        <Route exact path="/film/:id" component={FilmNew}></Route>
        <Redirect to="/" />
    </Switch>)

}
export default useRouter