import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import classes from '../singIn/singIn.module.css'
const Registration = (props) =>{
    const [login, setLogin] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePasword] = useState("")

    const [errorInf, setErrorInf] = useState("")

    const handlerRegis =(e)=>{
        e.preventDefault()
       
        if(login === "") {setErrorInf("Введите логин")}
        else if(email === "") {setErrorInf("Введите почту")}
        else if(password !== rePassword) {setErrorInf("Пароль не совпадает")}
        else {
            
        }

        console.log(login +" "+email+" "+password+" "+rePassword)
    }

    return(
        <div className={classes.panel}>
        <button className={classes.close} onClick={props.setIsOpen}><CloseIcon/></button>
        <form className={classes.form} onSubmit={handlerRegis}>
            
            <input name="login" placeholder="Логин" onChange={e => {setLogin(e.target.value); errorInf && setErrorInf("")}}></input>
            <input name="email" placeholder="Почта" onChange={e => {setEmail(e.target.value); errorInf && setErrorInf("")}}></input>
            <input type="password" placeholder="Пароль" onChange={e => {setPassword(e.target.value); errorInf && setErrorInf("")}}></input>
            <input type="password" placeholder="Повторить пароль" onChange={e => {setRePasword(e.target.value); errorInf && setErrorInf("")}}></input>
            <p style={{color:'white', marginRight:'auto', marginLeft:'auto'}}>{errorInf}</p>
            <div>
                <button type='submit'>Зарегистрироватся</button>
            </div>
        </form>

    </div>
    )
}
export default Registration;