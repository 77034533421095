import { DROP_FILM, GET_DATA_API, GET_FILM_DATA, HIDDEN_LOADER, HIDEN_ALERT, LOAD_TOP, SELECT_VIDEO, SET_STOREG_DATA, SHOW_ALERT, SHOW_LOADER, STOREG_NULL } from "./type"

const initealState = {
    loading :false,
    dataFilm:[],
    filmInfo:[],
    selectIDVideo:0,
    error: false,
    storegLoadin: true,
    hendlerApp:[]
}
export const Reducer= (state=initealState,action)=>{
    switch(action.type)
    {
        case SHOW_LOADER:
            return {...state,loading: true}
        case HIDEN_ALERT:
            return {...state, error:false, loading:false}
        case GET_DATA_API:
            return {...state, dataFilm:action.payload, loading:false}
        case SHOW_ALERT:
            return {...state, loading:false,error:true}
        case HIDDEN_LOADER:
            return {...state, loading:false}
        case SELECT_VIDEO:
            return {...state, selectIDVideo:action.payload}
        case SET_STOREG_DATA:
           return {...state, dataFilm:action.list, selectIDVideo:action.id, storegLoadin:false}
        case STOREG_NULL:
            return {...state, storegLoadin:false}
        case LOAD_TOP:
            return{...state, dataFilm:action.payload,loading:false}
        case GET_FILM_DATA:
            return {...state, filmInfo:action.payload, loading:false}
        case DROP_FILM:
            return {...state, dataFilm:action.payload}
        default: return state
    }
}