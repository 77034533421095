import classes from './contact.module.css'
import Navigation from '../../component/navigation/navigation'
import HeaderPanel from '../../component/header/header'
import { useState } from 'react'
const Contact = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <HeaderPanel setIsOpen={setIsOpen} isOpen={isOpen}></HeaderPanel>
      <Navigation isOpen={isOpen}></Navigation>
      <div className={classes.content}>
        <h2>Контакт</h2>
        <h4>Email: yoioio@gmail.com </h4>

        <h2>Информация для правообладателей</h2>
        <div>
          Ресурс yoioio.pw действует в соответствии с законодательством
          Российской Федерации о защите информации и авторских прав.
          <br /> Весь размещенный на сайте контент представляет собой материал,
          находящийся в свободном доступе для просмотра и скачивания в сети
          Интернет. Сбор доступных в Интернете материалов и их размещение в
          каталоге осуществляется в автоматическом режиме. Администрация сайта в
          данном случае не осуществляет контроль над добавляемым контентом.
          Администрация сайта также не осуществляет деятельность, связанную с
          публикацией нелицензионного контента, незаконно украденного и
          находящегося под защитой правообладателей. Автоматизированная система
          публикует только находящийся в свободном доступе материал из открытых
          источников.
          <br />
          <br />
          <p>
            Если Вы владелец авторских прав, просим Вас учесть, что все плееры
            на сайте из сторонних API источников. Пишите напрямую по контактным
            данным этих видео-хостингов, для соблюдения DMCA. В 99% случаев они
            удаляют спорный контент в течении 24 часов. Плееры недоступные на
            первоисточнике априори не будут отображаться на yoioio.pw. По
            вопросам рекламы в плеерах, ошибок, авторского права и всего
            остального, пишите на контактные данные видео-хостингов!
          </p>
        </div>
      </div>
    </div>
  )
}
export default Contact
